<template>
  <div v-if="isShowed">
    <v-overlay>
      <v-progress-circular
        color="#FBB040"
        indeterminate
        size="64"
      />
    </v-overlay>
  </div>
</template>

<script>
export default {
  name: "Loading",
  props: {
    isShowed: {
      type: Number,
      default: 0,
    },
  },
};
</script>
